import React, { useEffect, useState } from "react";
import styles from "../styles/Spinner.module.css";
import  "../css/Spinner.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useGlobalState } from "../state";

const Spinner = ({isWelcome,isLoading}) => {
  const [progressbar, setProgressbar] = useState(10);
  const [count, setCount] = useState(0);
  const [isModelLoaded, setIsModelLoaded] = useGlobalState("IsModelLoaded");

  useEffect(() => {
    if(progressbar < 94){
      const interval = setInterval(() => {
        setProgressbar(progressbar + 10);
        setCount(count + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [progressbar]);
  return (
    <div className={styles.wrapper}>
      {isModelLoaded ? <div style={{ width:'100%',position:"absolute",bottom:'4%',filter: isModelLoaded ? "blur(5px)" : "blur(0px)" }}  > </div>: ''}
      <div className={styles.loaderContainer}>
      {isWelcome == false ?   <div
          className={styles.progressbar}
          style={{ width: "4%", height: "4%" }}
        >
          {/* <CircularProgressbar value={progressbar}  /> */}
					{/* <div class="lds-ripple"><div></div><div></div></div> */}
					{/* <div>Loading</div> */}
					<div className="loader"></div>
        </div> : ""}
      </div>
    </div>
  );
};

export default Spinner;
