import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
    scene : null,
    showTour: false,
    selectedButton: null,
    dimBg: false,
    playBgMusic: false,
    showUC: false,
    showDC: false,
    fetched: false,
    useCase:0,
    playAndPause:true,
    IsButtonContainer:true,
    IsAutoPlay:false,
    IsTourOpen:false,
    IsModelOpen:false,
    HoverUseCaseId:0,
    HoverLabel:"",
		HoverId: 0,
    UCTourId:0,
    MfToEdgeCity:true,
    UmToDigitalCity:true,
    IsLoading:true,
    IsHomeButtonClick:false,
    IsWelcomVo:true,
    IsMuted:false,
		UmToManufacturing:false,
    ApplicationDB:'',
    IsModelLoaded:true,
		audioVO1: [],
		audioPathVO1: [],
		use_case_list: [],
		mapped_use_case: null,
    IsBackgroundBlur: false,
    clientXPosition1:-20,
    clientYPosition1:-20,
    currentZoomedSection:0,
		playUCDirectly: false,
		solutionsId:-1,
});

export {setGlobalState, useGlobalState};