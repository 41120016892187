import { gsap } from "gsap";
import sections from "../data/sections.json";
import { Vector3 } from "@babylonjs/core";
import usecases from "../data/usecases.json";
import { Howl } from "howler";
import { setGlobalState, useGlobalState } from "../utils/state";
import { ApplicationDB, assetsLocation } from "../assets/assetsLocation";
import { rotateToTarget, spiralAnimation } from "../utils/libraries/CameraUtils";

let IsTourOpen = true
const setTourState = (onOff)=>{
    IsTourOpen = onOff
}
const toggleSectionUIs = (scene) => {
    if (!scene) return;
    const advancedTexture = scene.getTextureByName("myUI");

    if (!advancedTexture) return;

    // sections.forEach((section) => {
    //     const container = advancedTexture.getControlByName(`section-${section.id}-container`);

    //     container.alpha = 0;

    //     gsap.to(container, {
    //         alpha: container.isVisible ? 1 : 0,
    //         duration: 0.5,
    //         ease: "power1.out",
    //         duration: 2,
    //     });

    // });

    // usecases.forEach((usecase) => {
    //     const container = scene.getMeshByName(`usecase-${usecase.id}-fake-mesh`);

    //     container.alpha = 0;
    //     container.isVisible = true;


    //     gsap.to(container, {
    //         alpha: container.isVisible ? 1 : 0,
    //         duration: 0.5,
    //         ease: "power1.out",
    //         duration: 2,
    //     });

    // });
};


const lookAt=(xCoordinate, yCoordinate, zCoordinate, cameraX, cameraY, cameraZ)=>{
    // Calculate the vector from the camera position to the target point
    let direction = new Vector3(xCoordinate - cameraX, yCoordinate - cameraY, zCoordinate - cameraZ);

		// Calculate the alpha angle (rotation around the vertical axis)
    let alpha = Math.atan2(direction.x, direction.z);
    
    // Calculate the beta angle (elevation from the horizontal plane)
    let distance = Math.sqrt(direction.x * direction.x + direction.z * direction.z);
    let beta = Math.atan2(direction.y, distance);
    
		if (Math.abs(alpha) > Math.abs(alpha + 2* Math.PI))
			alpha = alpha + 2 * Math.PI;
		if (Math.abs(beta) > Math.abs(beta + 2* Math.PI))
			beta = beta + 2 * Math.PI;
    // // Convert angles to the range expected by ArcRotateCamera
    // alpha = alpha < 0 ? alpha : alpha;
    // beta = beta < 0 ? beta + 2 * Math.PI : beta;
    return {alpha,beta}
}

const moveFirstTarget = (camera) => {
    const timeline = gsap.timeline();
    gsap.globalTimeline.add(timeline)
    const sound = new Howl({
        src: `${assetsLocation}${ApplicationDB}/audio/intros/1.mp3`,
        html5: true,
        preload:true
    })
    timeline
		.to(camera.rotation, {
			x: 0,
			y: lookAt(-58.141,2,-16.087, -54.126, 2, -19.147).alpha,
			duration: .4,
			ease: "power.inOut",
		})
		.to(camera.position, {
				x: -54.126,
				y: 2,
				z: -19.147,
				// **SK -- all move durations = 1, ease, all rotations 0.4, ease, combine rotation+ movement via lock target
				duration: 1,
				ease: "power.inOut",
            onComplete: () => {
                if (IsTourOpen) {
                sound.play();
                setGlobalState("UCTourId", 1);
                callNextTarget(camera, moveSecondTarget, sound);
                }
            }
        });
    // sound.on("end", function () {
    //     callNextTarget(camera, moveSecondTarget);
    //   });

};

const moveSecondTarget = (camera) => {
    const timeline = gsap.timeline();
    gsap.globalTimeline.add(timeline)
    const sound = new Howl({
        src: `${assetsLocation}${ApplicationDB}/audio/intros/2.mp3`,
        html5: true,
        preload:true

    })


    timeline
		.to(camera.rotation, {
			x: 0.2797833944525906,
			y: 0,
			duration: .4,
            ease: "power.inOut",
		})
		// .to(camera.position, {
    //     x: -57.508,
    //     y: 2,
    //     z: -19.147,
    //         duration: .4,
    //         ease: "power.inOut",
    // })
		// .to(camera.rotation, {
		// 	y: 0,
		// 	duration: .4,
    //         ease: "power.inOut",
		// })
		.to(camera.position, {
		x: -57.508,
		y: 2,
		z: 4.6328,
		duration: 1,
        ease: "power.inOut",
		})
    .to(camera.rotation, {
				x: 0,
				y: lookAt(-52,2,-1.3,-57.508,2,4.6328).alpha,
				duration: 0.8,
        ease: "power.inOut",
        onComplete: () => {
            if (IsTourOpen) {
            sound.play();
            setGlobalState("UCTourId", 2);
            callNextTarget(camera, moveThirdTarget, sound)
        }
        }
    });

    // sound.play();
    // sound.on("end", function () {
    //     callNextTarget(camera, moveThirdTarget);
    //   });


};

const moveThirdTarget = (camera) => {
  const sound = new Howl({
		src: `${assetsLocation}${ApplicationDB}/audio/intros/3.mp3`,
		html5: true,
		preload:true
	})
	const timeline = gsap.timeline();
	gsap.globalTimeline.add(timeline)

	timeline.to(camera.rotation, {
		x: 0.2797833944525906,
		y: -1.729744737715753,
		z: 0,
		duration: 0.4,
		ease:"power1.inOut"
	}).to(camera.position, {
			x: -68.739,
			y: 2,
			z: 4.6328,
			duration: 1,
			ease:"power1.inOut"
	})
	.to(camera.rotation, {
		x: 0,
		y: lookAt(-70,2,-0.5,-68.739,2,4.6328).alpha,
		duration: 0.8,
		ease:"power1.inOut",
		onComplete: () => {
				if (IsTourOpen) {
				sound.play();
				setGlobalState("UCTourId", 3);
				callNextTarget(camera, moveFourthTarget, sound)
			}
		}
	});
};

const moveFourthTarget = (camera) => {
	const sound = new Howl({
		src: `${assetsLocation}${ApplicationDB}/audio/intros/4.mp3`,
		html5: true,
		preload:true

})
const timeline = gsap.timeline();
gsap.globalTimeline.add(timeline)

timeline.to(camera.rotation, {
		x: 0.2797833944525906,
		y: -1.729744737715753,
		z: 0,
		duration: 0.4,
		ease:"power1.inOut"
	}).to(camera.position, {
		x: -87.06,
		y: 2,
		z: 4.6328,
		duration: 1,
		ease:"power1.inOut"
	})
.to(camera.rotation, {
		x: 0,
		y: lookAt(-90,2,-1.5,-87.06,2,4.6328).alpha,
		duration: 0.8,
		ease:"power1.inOut",
		onComplete: () => {
				if (IsTourOpen) {
				sound.play()
				setGlobalState("UCTourId", 4);
				callNextTarget(camera, moveFifthTarget, sound)
		}
		}
});

};

const moveFifthTarget = (camera) => {
	const sound = new Howl({
		src: `${assetsLocation}${ApplicationDB}/audio/intros/5.mp3`,
		html5: true,
		preload:true

})
const timeline = gsap.timeline();
gsap.globalTimeline.add(timeline)
timeline.to(camera.rotation, {
		x: 0.2797833944525906,
		y: -1.729744737715753,
		duration: 0.4,
		ease:"power1.inOut"
	}).to(camera.position, {
		x: -94.672,
		y: 2,
		z: 4.6328,
		duration: 1,
		ease:"power1.inOut"
	}).to(camera.rotation, {
		y: -Math.PI,
		duration: 0.4,
		ease:"power1.inOut"
	})
.to(camera.position, {
		x: -94.672,
		y: 2,
		z: -8.8227,
		duration: 1,
		ease:"power1.inOut"
	})
.to(camera.rotation, {
		x: 0,
		// y: lookAt(-94, 2, -16, -94.672, 2, -8.8227).alpha,
		duration: 0.8,
		ease:"power1.inOut",
		onComplete: () => {
			if (IsTourOpen) {
				sound.play()
				setGlobalState("UCTourId", 5);
				callNextTarget(camera, moveSixthTarget, sound)
			}
		}
});
};

const moveSixthTarget = (camera) => {
    const sound = new Howl({
        src: `${assetsLocation}${ApplicationDB}/audio/intros/6.mp3`,
        html5: true,
        preload:true

    })
		const timeline = gsap.timeline();
    gsap.globalTimeline.add(timeline)
    timeline.to(camera.rotation, {
        x: 0.2797833944525906,
        duration: 0.4,
				ease:"power1.inOut"
			}).to(camera.position, {
        x: -94.672,
        y: 2,
        z: -19.036,
        duration: 1,
				ease:"power1.inOut"
			}).to(camera.rotation, {
			y: - 2*Math.PI + 1.729744737715753,
			duration: 0.4,
			ease:"power1.inOut"
    })
    .to(camera.position, {
        x: -65.539,
        y: 2,
				z: -19.036,
        duration: 1,
				ease:"power1.inOut"
			})
    .to(camera.rotation, {
        x: 0,
				y: lookAt(-70,2,-12,-65.539,2,-19.036).alpha - 2*Math.PI,
        duration: 0.8,
				ease:"power1.inOut",
				onComplete: () => {
            if (IsTourOpen) {
            sound.play()
            setGlobalState("UCTourId", 6);
            callNextTarget(camera, moveEleventhTarget, sound);
        }
        }
    })
};

const moveEleventhTarget = (camera) => {
	// const sound = new Howl ({
	//     src: `${assetsLocation}${ApplicationDB}/audio/intros/14.mp3`,
	//     html5: true
	// })
	const timeline = gsap.timeline();
	gsap.globalTimeline.add(timeline)

	timeline.to(camera.rotation, {
			x: 0.2797833944525906, 
			duration: 0.4,
			ease:"power1.inOut",
			onComplete: () => {
					// sound.play()
					setGlobalState("UCTourId", 0);
					setGlobalState("IsTourOpen", false);
					moveToOuterCamera(camera);
			}

	})

};

const moveToOuterCamera = (camera) => {
	setGlobalState("UCTourId", 0);
	const scene = camera.getScene();
	const arcRotateCamera = scene.getCameraByName('camera-2');
	const cam3 = scene.getCameraByName('camera-3');
	const canvas = document.getElementsByClassName("main-canvas")[0];

	scene.activeCamera.computeWorldMatrix();
	cam3.position.copyFrom(scene.activeCamera.position);
	cam3.setTarget(scene.activeCamera.target.clone());
	arcRotateCamera.restoreState();
	arcRotateCamera.computeWorldMatrix();
	rotateToTarget(scene, arcRotateCamera.target, cam3, 0.4, spiralAnimation, scene, arcRotateCamera.target, cam3.position, arcRotateCamera.position, 1000, 1, (arcRotateCamera, canvas) => { scene.activeCamera = arcRotateCamera; arcRotateCamera.attachControl(canvas, true); enableCameraMovement(camera); setGlobalState("IsTourOpen", false); }, arcRotateCamera, canvas);
}



const moveCameraOnClose = (camera) => {

    const closeBtn = document.querySelector("#close-btn");
    // closeBtn.setAttribute("disabled", true);
    const timeline = gsap.timeline();

    const scene = camera.getScene();

    const camera2 = scene.getCameraByName("camera-2")
    camera2.restoreState();
    scene.activeCamera = camera2;
    toggleSectionUIs(scene);

    timeline
        .to(camera.position, {
            x: -16,
            y: 33,
            z: 58,
            duration: 1,
            ease: "power1.inOut"
        })


    timeline
        .to(camera.rotation, {
            x: 0.27,
            y: - 2.8,
            z: 0,
            duration: 3.5,
            ease: "power1.inOut",
            onComplete: () => {






                // here
                // const advancedTexture = scene.getTextureByName("myUI");

                // if (!advancedTexture) return;


                // const container = advancedTexture.getControlByName(`section-1-container`);

                // if (!container.isVisible){enableCameraMovement(camera);}
                enableCameraMovement(camera);

                const closeBtn = document.querySelector("#close-btn");
                closeBtn.removeAttribute("disabled");

                const startBtn = document.querySelector("#tour");
                startBtn.removeAttribute("disabled");


            }

        })
};

// const moveCameraToIT = (camera) => {

//     gsap.to(camera.position, {
//         x: -67,
//         y: 4.63,
//         z: 24.4,
//         duration: 3,
//         ease: "power1.out"
//     })

//     gsap.to(camera.rotation, {
//         x: 0.13,
//         y: -2.8,
//         z: 0,
//         duration: 3,
//     })


// };



const disableCameraMovementOnTour = (camera) => {
    camera.inputs.attached.mouse.detachControl();
};


const enableCameraMovement = (camera) => {

    // check if camera is attached to mouse input 
    if (!camera || !camera.inputs || camera.inputs.attached.mouse) return;

    // camera.inputs.attachInput(camera.inputs.attached.mouse);
    camera.inputs.addMouseWheel();
    camera.inputs.addPointers();
};

const startAnimations = (scene) => {



    const freeCam = scene.getCameraByName("camera-1");


    freeCam.position = new Vector3(-20.13, 2, -19.147);
		freeCam.setTarget(new Vector3(-58.141, 3.5, -16.087));

    scene.activeCamera = freeCam;

    const startBtn = document.querySelector("#tour");
    // startBtn.setAttribute("disabled", true);

    disableCameraMovementOnTour(freeCam);
    gsap.globalTimeline.getChildren().forEach(child => child.kill());
		const bgMusic = new Howl({
			src: `${assetsLocation}${ApplicationDB}/audio/uc_music/immersive.mp3`,
			loop: true,
			volume: 0.2,
			html5: true
		});
    const sound = new Howl({
			src: `${assetsLocation}${ApplicationDB}/audio/intros/0.mp3`,
			html5: true
    })
		bgMusic.play();
    sound.play();
		bgMusic.on('playerror', function(error) {
			console.error('Error playing background music:', error);
		});
    sound.on("end", function () {
        moveFirstTarget(freeCam);
    });
    // setTimeout(() => {
    // }, 1500);

};



const callNextTarget = (camera, fn, sound) => {
    
    sound.on("end", function () {
        setGlobalState("UCTourId", 0);
        fn(camera);
    });

}

export { startAnimations, moveCameraOnClose, toggleSectionUIs, enableCameraMovement, disableCameraMovementOnTour,setTourState };